import axios from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Alert, Button, Form, Input, Modal, Row, Spin, openNotification } from '@servahealth/serva-ui-kit';

const verifyCode = (variables) => {
	return axios.post(
		`${process.env.REACT_APP_API_ROOT}/V1/Auth/twoFactor`,
		variables
	);
};

const resendCode = (variables) => {
	return axios.post(
		`${process.env.REACT_APP_API_ROOT}/V1/Auth/resendTwoFactor`,
		variables
	);
};

const TwoFactorModal = (props) => {
	const { visible, email, onSuccess, onCancel, authType, codeChallenge, codeChallengeMethod, clientId } = props;
	const [error, setError] = useState(false);
	const [form] = Form.useForm();

	const onOk = () => {
		const body = {
			email: email,
			twoFactor: form.getFieldValue('inputCode'),
			code_challenge: codeChallenge,
			code_challenge_method: codeChallengeMethod,
			client_id: clientId,
			userAgent: navigator.userAgent,
			platform: navigator.platform,
			oscpu: navigator.oscpu
		};
		verify(body);
	};

	const handleCancel = () => {
		form.resetFields();
		setError(false);
		onCancel();
	};

	const handleVerificationSuccess = (data) => {
		onSuccess(data.data);
		onCancel();
	};

	const handleVerificationError = () => {
		setError(true);
	};

	const { mutate: verify, isLoading: isVerifying } = useMutation(verifyCode, {
		onSuccess: handleVerificationSuccess,
		onError: handleVerificationError,
	});

	const handleSendCodeSuccess = () => {
		openNotification('success', 'A new code has been sent');
	};

	const handleSendCodeError = () => {
		openNotification('error', 'Could not send a new code');
	};

	const { mutate: sendNewCode, isLoading: isSendingNewCode } = useMutation(
		resendCode,
		{
			onSuccess: handleSendCodeSuccess,
			onError: handleSendCodeError,
		}
	);

	const onClickNewCode = () => sendNewCode({ email: email });

	return (
		<Modal
			title='Two Factor Authentication'
			open={visible}
			onCancel={handleCancel}
			confirmLoading={isVerifying}
			closable
			destroyOnClose
			width={500}
			maskClosable={false}
			footer={null}
			hideButtons
		>
			{error && (
				<Alert
					className='mb-2'
					type='error'
					message='Authentication failed. Try again.'
					showIcon
				/>
			)}
			<Row className='d-flex justify-content-center'>
				<p className='text-center mb-4'>
					A verification code has been sent to your {authType}.
					<br />
					This code will be valid for 15 minutes.
				</p>
			</Row>
			<Row className='d-flex justify-content-center'>
				<Form form={form} layout='inline' className='form-label-bold'>
					<Form.Item
						label='MFA Code'
						name='inputCode'
						validateStatus={error && 'error'}
					>
						<Input maxLength={8} autoFocus />
					</Form.Item>
					<Form.Item>
						<Button onClick={onOk} type='primary' htmlType='submit'>
							Verify
						</Button>
					</Form.Item>
				</Form>
			</Row>
			<Row className='mt-4'>
				<span>
					Didn't receive a code? Click{' '}
					<Button
						type='link'
						className='p-0'
						disabled={isSendingNewCode}
						onClick={onClickNewCode}
					>
						here
					</Button>{' '}
					to resend
					<Spin className='ml-2' spinning={isSendingNewCode} size='small' />
				</span>
			</Row>
		</Modal>
	);
};

export default TwoFactorModal;
