import { Result, ConfigProvider } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { 
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Row,
    Content
} from '@servahealth/serva-ui-kit';
import LoginHeader from './LoginHeader';
import Footer from './Footer';

const postResetPassword = ({ email }) => {
	return axios.post(
		`${process.env.REACT_APP_API_ROOT}/V1/Auth/reset?emailAddress=${email}`
	);
};

const ForgotMyPassword = () => {
	const [emailSubmitted, setEmailSubmitted] = useState(false);

	const onSuccess = () => {
		setEmailSubmitted(true);
	};

	const {
		mutate: resetPassword,
		isLoading,
	} = useMutation(postResetPassword, { onSuccess: onSuccess });

	return (
		<ConfigProvider 
			theme={{
				token: {
					colorPrimary: '#009ee2'
				}
		  	}}
		>
			<Layout style={{ minHeight: '100vh', background: '#f1f6f8' }}>
				<LoginHeader />
				<Content>
					<Row justify='center' align='middle'>
						<Col sm={12} md={10} lg={8} xl={6}>
							<Card className='text-center' bodyStyle={{ paddingBottom: 10 }}>
								
								{emailSubmitted ? (
									<>
										<Result
											status="success"
											title="Successfully Sent Password Reset"
											subTitle={(
												<>
													<p>
														Check your email for a link to reset your password. If it
														doesn't appear within a few minutes, check your spam folder.
													</p>
													<p>
														<b>You may close this browser tab at any time.</b>
													</p>
												</>
											)}
										/>
									</>
								) : (
									<Form
										name='resetPassword'
										className='justify-content-center'
										onFinish={resetPassword}
										layout='vertical'
									>
										<h5 className='mb-3'>Reset Password</h5>
										<Form.Item
											name='email'
											label='Enter your email address and we will send you a password reset link.'
										>
											<Input placeholder='Enter your email' />
										</Form.Item>
										<Form.Item>
											<Button
												type='primary'
												htmlType='submit'
												loading={isLoading}
											>
												Send password reset email
											</Button>
										</Form.Item>
									</Form>
								)}
							</Card>
						</Col>
					</Row>
				</Content>
				<Footer />
			</Layout>
		</ConfigProvider>
	);
};

export default ForgotMyPassword;
