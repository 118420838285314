import LoginForm from './LoginForm';
import LoginReset from './LoginReset';
import { Layout, Content } from '@servahealth/serva-ui-kit';
import { useSearchParams } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Footer from './Footer';
import bgimg from '../../images/ContactUs-bg.png'

const Login = () => {
	const [params] = useSearchParams();
	const { reset } = Object.fromEntries(params);

	return (
		<ConfigProvider 
			theme={{
				token: {
					colorPrimary: '#009ee2'
				}
		  	}}
		>
			<Layout style={{ minHeight: '100vh', backgroundImage: `url(${bgimg})` }}>
				<Content>
					{reset ? <LoginReset resetToken={reset} /> : <LoginForm />}
				</Content>
				<Footer />
			</Layout>
		</ConfigProvider>
	);
};

export default Login;
