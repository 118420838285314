import { Skeleton, Image } from 'antd';
import { Row } from '@servahealth/serva-ui-kit';
import servaLogo from '../../images/ServaHealth-logo.svg';
import { useRef, useState } from 'react';

const LoginHeader = ({ clientId = '' }) => {
	const imgRef = useRef();
	const [load, setLoad] = useState(false);

	return (
		<Row justify='center'>
			{!load && <Skeleton title={false} paragraph={{ rows: 2 }} style={{ maring: '0 auto', width: 300, height: 200 }} active />}
			<Image 
				preview={false} 
				fallback={servaLogo} 
				ref={imgRef} 
				alt="logo" 
				style={{ maxWidth: 900 }}
				onLoad={() => setLoad(true)} 
				src={`${process.env.REACT_APP_API_ROOT}/logos/${clientId}.png`} 
			/>
		</Row>
	);
};

export default LoginHeader;
