import { useState } from 'react';
import axios from 'axios';
import { Result, Text, Form, Input, Button, Row, Col, Card, Spin, Alert, Space } from '@servahealth/serva-ui-kit';
import { useMutation } from 'react-query';
import { openNotification } from '@servahealth/serva-ui-kit';
const LoginReset = (props) => {
	const { resetToken } = props;

	const [password, setPassword] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);

	const onResetSuccess = () => {
		openNotification('success', 'Password saved. You can close this browser tab at any time.');
		setIsSuccess(true);
	};

	const onResetError = (res) => {
		console.log(res);
		openNotification('error', 'There was an issue resetting your password. If you clicked Forgot my Password multiple times, please make sure you are using the most recent email link.');
	};


	const { mutate, isLoading, isError, error } = useMutation(
		() => {
			return axios.post(`${process.env.REACT_APP_API_ROOT}/V1/Auth/completeReset`, {
				password: password,
				token: resetToken
			});
		},
		{ 
			onSuccess: onResetSuccess,
			onError: onResetError  
		}
	);

	const handleSubmit = () => {
		mutate();
	};

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	};

	return (
		<Row type='flex' justify='center' align='middle'>
			<Col>
				<Card className='text-center' bodyStyle={{ paddingBottom: 10 }}>
					<Space direction='vertical'>
						<h5>Set New Password</h5>
						{!isSuccess && <Text type='secondary'>Password must be at least 8 characters and contain an uppercase letter, number, and a non-alphanumeric character.</Text>}
						{isError ? (
							<Alert
								message={error.response?.data.message ?? 'Server Error'}
								type='error'
								showIcon
								className='mb-2'
							/>
						) : null}
						{!isSuccess && <Form
							name='normal_login'
							className='justify-content-center'
							initialValues={{
								remember: true,
							}}
							onFinish={handleSubmit}
							layout='vertical'
						>
							<Form.Item
								name='password'
								label='New Password'
								rules={[
									{
										required: true,
										message: 'Password is required',
									},
								]}
							>
								<Input
									value={password}
									onChange={handlePasswordChange}
									type='password'
								/>
							</Form.Item>
							<Form.Item
								name='confirmPassword'
								label='Confirm New Password'
								hasFeedback
								dependencies={['password']}
								rules={[
									{
										required: true,
										message: 'Please confirm your password!',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue('password') === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												'The two passwords that you entered do not match!'
											);
										},
									}),
								]}
							>
								<Input
									value={password}
									onChange={handlePasswordChange}
									type='password'
								/>
							</Form.Item>
							<Form.Item>
								<Button
									type='primary'
									htmlType='submit'
									block
									disabled={isLoading || isSuccess}
								>
									{isLoading || isSuccess ? <Spin /> : 'Reset Password'}
								</Button>
							</Form.Item>
						</Form>}
						{isSuccess && (
							<Result
								status="success"
								title="Successfully Reset password"
								subTitle="You may close this browser tab at any time."
							/>
						)}
					</Space>
				</Card>
			</Col>
		</Row>	
	);
};

export default LoginReset;
