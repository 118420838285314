import React from 'react';
import { Online } from '@servahealth/serva-ui-kit';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthenticationContextProvider from '../Auth';
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';
import Login from './Login';
import ForgotMyPassword from './Login/ForgotPassword';

const queryClient = new QueryClient();

function App() {

    return (
        <QueryClientProvider client={queryClient}>
            <AuthenticationContextProvider>
                <div>
                    <Online />
                    <Router>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/loginredirect" element={<Login />} />
                            <Route path="/password_reset" element={<ForgotMyPassword />} />

                            {/* <Route element={<Layout />}>
                                <Route path="/home" element={<Home />} />
                            </Route> */}
                        </Routes>
                    </Router>
                </div>
            </AuthenticationContextProvider>
        </QueryClientProvider>
    );
}

export default App;
