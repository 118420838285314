import { Layout } from 'antd';
const { Footer: AntFooter } = Layout;

/**
 * Sign-in footer
 */
const Footer = () => {
    return (
        <AntFooter style={{ userSelect: "none", background: 'transparent' }} className='text-center'>
            {'\xA9'}
            {(new Date()).getFullYear()}
            {' Serva Health LLC'}
        </AntFooter>
    )
};

export default Footer;